export interface NcgToken {
    expires_in: string;
    access_token: string;
    message?: string;
}

export interface NcgQuery<N = NcgNode> {
    _embedded: {
        items: N[];
    };
}

export interface NcgNode {
    _links: any;
    identifier: string;
}

export interface NcgVehicle extends NcgNode {
    values: NcgVehicleValue[];
    energy_classes: string[];
    legal: string;
    prices_from: NcgVehiclePrices;
    trims: NcgVehicleTrim[];
}

export interface NcgVehiclePrice {
    value: number;
    occurrences: string;
    currency: string;
    legal: string;
}

export interface NcgVehiclePrices {
    price_cash?: NcgVehiclePrice;
    price_owners_fee?: NcgVehiclePrice;
    price_cash_campaign?: NcgVehiclePrice;
}

export interface NcgVehicleFeaturedAttribute {
    attribute_code: string;
    attribute_group: string;
}

export interface NcgVehicleTrim {
    trim: string;
    trim_label: string;
    trim_parent: string;
    energy_classes: string[];
    legal: string;
    prices_from: NcgVehiclePrices;
    value_groups: NcgVehicleValueGroups;
    value_groups_formatted: NcgVehicleValueKey[];
    engines: NcgVehicleEngine[];
    featured_attributes?: NcgVehicleFeaturedAttribute[];
}

export interface NcgVehicleEngine {
    engine_size: string;
    engine_size_label: string;
    value_groups: NcgVehicleValueGroups;
    value_groups_formatted: NcgVehicleValueKey[];
    prices: NcgVehiclePrices;
}

export interface NcgVehicleSimpleValue {
    code: string;
    label: string;
    value: string;
    sublabel?: string;
}

export interface NcgVehicleValueGroups {
    [key: string]: NcgVehicleValueKey;
}

export interface NcgVehicleValueKey {
    label: string;
    values: NcgVehicleValue[];
}

export type NcgVehicleValue = NcgVehicleValueMetric | NcgVehicleValueText | NcgVehicleValueReference | NcgVehicleValueOptions | NcgVehicleValueObject;

export interface NcgVehicleValueMetric {
    type: 'metric';
    code: string;
    value: { amount: string; unit: string };
    label: string;
    option_code?: string;
}

export interface NcgVehicleValueText {
    type: 'text' | 'textarea' | 'option' | 'date';
    code: string;
    value: string;
    label: string;
    option_code?: string;
}

export interface NcgVehicleValueReference {
    type: 'reference';
    code: string;
    value: string[];
    label: string;
    option_code?: string;
}

export interface NcgVehicleValueObject {
    type: 'object';
    code: string;
    value: Record<string, string | number>[];
    label: string;
    option_code: string | null;
}

export interface NcgVehicleValueOption {
    code: string;
    value: string;
}

export interface NcgVehicleValueOptions {
    type: 'options';
    code: string;
    value: NcgVehicleValueOption[];
    label: string;
    option_code?: string;
}

export interface NcgConsent {
    id: number;
    code: string;
    label: string;
    version: string;
    attributes: {
        title: string;
        content: string;
    };
}

export interface NcgResponse {
    [key: string]: {
        status: number;
        body?: {
            id: number;
        };
        errors?: {
            property: string;
            error: string;
        }[];
    };
}

export const LOCATION_CATEGORY_RETAIL = 'retail';
export const LOCATION_CATEGORY_WORKSHOP = 'workshop';
export const LOCATION_CATEGORY_SHOWROOM = 'showroom';
export const LOCATION_CATEGORY_TESTDRIVEONLY = 'testdriveonly';
export const LOCATION_CATEGORY_SPAREPARTS = 'spareparts';
export type NcgLocationCategory =
    | ''
    | typeof LOCATION_CATEGORY_RETAIL
    | typeof LOCATION_CATEGORY_WORKSHOP
    | typeof LOCATION_CATEGORY_SHOWROOM
    | typeof LOCATION_CATEGORY_TESTDRIVEONLY
    | typeof LOCATION_CATEGORY_SPAREPARTS;
export type NcgLocationFormCategory = typeof LOCATION_CATEGORY_RETAIL | typeof LOCATION_CATEGORY_WORKSHOP | typeof LOCATION_CATEGORY_SPAREPARTS;
export type NcgLocationMapCategory =
    | typeof LOCATION_CATEGORY_RETAIL
    | typeof LOCATION_CATEGORY_WORKSHOP
    | typeof LOCATION_CATEGORY_SPAREPARTS
    | typeof LOCATION_CATEGORY_TESTDRIVEONLY;

export interface NcgListItem {
    id: number;
    name: string;
}

export interface NcgLists {
    'crm-lists': NcgListItem[];
    'newsletter-groups': NcgListItem[];
}

export interface NcgProductCatalog {
    productcatalogid: number;
    businessidentifier: string;
    productcatalogtitle: string;
    productcatalogdescription: string;
    productcatalogdurationinminutes: number;
    productcatalogcurrencyname: string;
    productcatalogprice: string | null;
    productcatalogsorting: number;
    productcatalogautovisionid: string;
    productcatalogdracarid: string;
    procuctcatalogvideourl: string | null;
}

export type NcgBookingDates = string[];

export interface NcgBookingTimes {
    onlineservicebookingtimeslots: NcgServiceBookingTimeslot[];
}

export interface NcgServiceBookingTimeslot {
    availabledate: string;
    availableservicetimeslots: NcgTimeSlot[];
}

export interface NcgProductCatalogServices {
    productcatalogservices: NcgProductCatalog[];
}

export interface NcgBusinessTimeslotData {
    businessidentifier: string;
    storecode: string;
    source: string;
    availabletimes: NcgAvailableTimes;
}

export interface NcgAvailableTimes {
    lastupdated: string;
    availableservicetimeslots: NcgTimeSlot[];
}

export interface NcgTimeSlot {
    starttime: string;
    endtime: string;
    isrentalavailable: boolean;
}

export interface NcgLoyalty {
    vin: string;
    registration: string;
    preferredServiceRetailerSiteCode: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    postcode: string;
}

export interface NcgAuthorityDetails {
    RequestStatus?: {
        Status?: 'Ok';
    };
    LatestRegistrationState?: {
        Date?: {
            Day?: number;
            Hour?: number;
            Millisecond?: number;
            Minute?: number;
            Month?: number;
            Second?: number;
            Year?: number;
        };
        Details?: {
            AuthorityVehicleType?: {
                Code?: string;
                Name?: string;
            };
            AuthorityVehicleUsage?: {
                Code?: string;
                Name?: string;
            };
            Date?: {
                Day?: number;
                Hour?: number;
                Millisecond?: number;
                Minute?: number;
                Month?: number;
                Second?: number;
                Year?: number;
            };
            Insurance?: {
                InsuranceCompanyName?: string;
            };
            Leasing?: {
                ExpirationDate?: null | string;
                LeasingType?: string;
                LeasingTypeDisplayText?: string;
            };
            NumberPlateDescriptions?: string[];
            OwnerAcquisitionTime?: {
                Day?: number;
                Hour?: number;
                Millisecond?: number;
                Minute?: number;
                Month?: number;
                Second?: number;
                Year?: number;
            };
            Owners?: OwnerOrUser[];
            Permissions?: Permission[];
            RegistrationNumber?: string;
            UserAcquisitionTime?: {
                Day?: number;
                Hour?: number;
                Millisecond?: number;
                Minute?: number;
                Month?: number;
                Second?: number;
                Year?: number;
            };
            Users?: OwnerOrUser[];
            VehicleType?: string;
        };
        Registered?: boolean;
    };
    Vehicle?: {
        AuthorityIdentifier?: number;
        AuthorityModelDesignation?: string;
        AuthorityVehicleUsage?: CodeNamePair;
        CurrentAnnualEnvironmentalSupplementTax?: number;
        CurrentOwnershipTaxItems?: TaxItem[];
        FirstRegistrationDate?: {
            Day?: number;
            Month?: number;
            Year?: number;
        };
        Inspections?: Inspection[];
        Make?: string;
        Model?: string;
        ModelVariantDesignation?: string;
        ModelYear?: number;
        NextInspectionDate?: {
            Day?: number;
            Month?: number;
            Year?: number;
        };
        Segment?: string;
        StandardEquipment?: Equipment[];
        TechnicalDetails?: TechnicalDetails;
        TotalNumberOfOwners?: number;
        UsedVehicleImport?: boolean;
        Variant?: string;
        VariantCode?: string;
        VehicleId?: number;
        VehicleIdentificationNumber?: string;
        VehicleType?: string;
        WasDemo?: boolean;
    };
}

interface OwnerOrUser {
    Identity?: {
        ClassificationCode?: string;
        DisplayText?: string;
        IdentityType?: string;
    };
    Primary?: boolean;
}

interface Permission {
    Code?: number;
    Name?: string;
}

interface TaxItem {
    TypeId?: number;
    TypeName?: string;
    YearlyAmount?: number;
}

interface Inspection {
    Date?: {
        Day?: number;
        Month?: number;
        Year?: number;
    };
    OdometerReading?: string;
    ResultName?: string;
    TypeName?: string;
}

interface Equipment {
    Code?: string;
    Name?: string;
}

interface TechnicalDetails {
    AirbagQuantity?: number;
    AuthorityFuelType?: CodeNamePair;
    AuthoritySafetyRating?: number;
    AxleQuantity?: number;
    AxleSpacings?: {
        SpacingNumber?: number;
        SpacingValue?: UnitValue<'mm'>;
    }[];
    AxleTracks?: {
        Axle?: number;
        Track?: UnitValue<'mm'>;
    }[];
    Bodywork?: {
        AuthorityName?: string;
        ManufacturerName?: string;
    };
    CO2Emission?: UnitValue<'g/km'>;
    COEmission?: UnitValue<'g/km'>;
    Colour?: {
        AuthorityName?: string;
        ManufacturerName?: string;
    };
    CouplingDevice?: boolean;
    DoorQuantity?: number;
    DrivebySoundLevel?: UnitValue<'dB(A)'>;
    EUTypeApproval?: string;
    EngineCapacity?: UnitValue<'cm³'>;
    EngineCylinderQuantity?: number;
    EngineMaximumPower?: {
        HorsePower?: number;
        KiloWatts?: number;
    };
    ExhaustEmissionLevel?: string;
    FuelConsumption?: {
        KMPerLiter?: number;
        LitresPer100KM?: number;
    };
    FuelConsumptionForTaxation?: {
        KMPerLiter?: number;
    };
    FuelType?: CodeNamePair;
    Height?: UnitValue<'mm'>;
    IntegratedChildSeatQuantity?: number;
    Length?: UnitValue<'mm'>;
    MaximumAxleMass?: UnitValue<'kg'>;
    MaximumBrakedTrailerMass?: UnitValue<'kg'>;
    MaximumCombinationMass?: UnitValue<'kg'>;
    MaximumLadenMass?: UnitValue<'kg'>;
    MaximumSpeed?: UnitValue<'km/t'>;
    MaximumUnbrakedTrailerMass?: UnitValue<'kg'>;
    MinimumRunningOrderMass?: UnitValue<'kg'>;
    NOxEmission?: UnitValue<'mg/km'>;
    NedcCO2EmissionGramsPerKm?: number;
    NedcFuelConsumptionLitresPer100Km?: number;
    ParticulateFilter?: boolean;
    PassengerCapacity?: number;
    PluginHybrid?: boolean;
    PoweredAxles?: number[];
    RegistrationTaxRate?: string;
    RegistrationVehicleCategory?: string;
    RunningOrderMass?: UnitValue<'kg'>;
    SeatQuantity?: number;
    SeatbeltReminderQuantity?: number;
    StationarySoundLevel?: UnitValue<'dB(A)'>;
    StationarySoundLevelEngineRPM?: number;
    TechnicallyPermissibleMaximumLadenMass?: UnitValue<'kg'>;
    TypeApprovalVehicleCategory?: string;
    VehicleIdentificationNumberLocation?: string;
    WheelConfigurations?: string;
    Width?: UnitValue<'mm'>;
}

interface CodeNamePair {
    Code?: string;
    Name?: string;
}

interface UnitValue<T> {
    Unit?: T;
    Value?: number;
}
